import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Link } from "gatsby-theme-material-ui";
import { getNavEntryByTo } from "structure";

export default function ImageNav({ to }) {
  const entry = getNavEntryByTo(to);
  if (!entry) {
    return null;
  }
  const {
    title,
    asNav: { img },
  } = entry;
  return (
    <Box
      component={Link}
      to={to}
      sx={{
        display: "flex",
        justifyContent: { xs: "space-between", sm: "flex-end" },
        padding: 0,
        paddingTop: 2,
        paddingBottom: 2,
        alignItems: "flex-start",
        flexDirection: {
          xs: "row",
          sm: "row-reverse",
        },
        textDecoration: "none",
      }}
    >
      <Box>
        <Typography
          color="textSecondary"
          variant="subtitle"
          fontWeight="bold"
          display="block"
        >
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          paddingLeft: { xs: 2, sm: 0 },
          paddingRight: { xs: 0, sm: 2 },
        }}
      >
        <Box component="img" src={img} alt={title} sx={{ width: 100 }} />
      </Box>
    </Box>
  );
}
