import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ImageBlock from "src/components/ImageBlock";
import Paragraph from "src/components/Paragraph";
import { Link } from "gatsby-theme-material-ui";
import * as React from "react";
import { Helmet } from "react-helmet";
import Blockquote from "src/components/Blockquote";
import Container from "src/components/Container";
import ImageHeaderUnit from "src/components/ImageHeaderUnit";
import ImageNavList from "src/components/ImageNavList";
import Layout from "src/components/Layout";
import SubHeader from "src/components/SubHeader";
import crossroadsOutsideImg from "src/images/what-we-do/crossroads-outside.jpg";
import crossroadsImg from "src/images/what-we-do/crossroads.jpg";
import kidsImg from "src/images/what-we-do/kids.jpg";
import img from "src/images/what-we-do/swing.jpg";
import youngAdultsImg from "src/images/what-we-do/young-adults.jpg";

export default function ResidentialServicesPage() {
  return (
    <Layout>
      <Helmet>
        <title>Residential Services</title>
      </Helmet>
      <ImageHeaderUnit
        subtitle={
          <>
            <Link to="/what-we-do">What We Do</Link> &raquo; Residential
            Services
          </>
        }
        title="Comprehensive Support From Emergency to Permanent Housing"
        img={img}
        maxTitleWidth={450}
        backgroundPosition="50% 20%"
      />
      <Container>
        <SubHeader>Our residential programs at a glance</SubHeader>
        <Paragraph>
          Through EOCP’s residential programs, we effectively and
          comprehensively support people experiencing homelessness to transition
          into permanent housing and a new chapter of well-being:
        </Paragraph>
        <Paragraph component="ul">
          <li>
            <b>Crossroads;</b> serving a diverse range of individual adults and
            families
          </li>
          <li>
            <b>
              Matilda Cleveland, Families in Transition, and Family Matters;
            </b>{" "}
            dedicated programs for single parent/caregiver families with
            children
          </li>
          <li>
            <b>Our House;</b> a 10-bed program for young adults
          </li>
        </Paragraph>
      </Container>

      <Blockquote sx={{ mx: { sm: 3 } }}>
        <Box
          component="img"
          src={crossroadsImg}
          sx={{
            maxWidth: 420,
            display: "block",
            width: { xs: "100%", sm: "33vw" },
            float: { sm: "left" },
            mb: { xs: 3, sm: 0 },
          }}
          ml="auto"
          mr={{ xs: "auto", sm: 3 }}
        />
        At Crossroads, <b>our flagship facility for dignified care,</b> we
        provide individual adults and families with emergency housing and a safe
        place to sleep, meals, and committed partnership in moving past
        homelessness.
        <Box sx={{ clear: "both" }} />
      </Blockquote>

      <Box sx={{ display: { sm: "flex" }, mb: { sm: 4 } }}>
        <Container sx={{ width: { sm: "auto" } }}>
          <Paragraph>
            <b>A truly diverse intersection of personal journeys</b> come
            together at Crossroads: families, single adults, young adults,
            seniors, veterans, formerly incarcerated people, people of
            transgender experience, people living with mental illness or
            HIV/AIDS. Crossroads also houses the first Respite Care program for
            those discharged from Alameda County’s public health system, who
            need housing while recuperating.
          </Paragraph>
          <Paragraph>
            Crossroads is <b>more than just beds and meal service.</b> We begin
            by <b>building trusting relationships</b>, and each person works
            closely with an EOCP staff partner. Through a personalized approach,
            we <b>address immediate concerns and connect people to services</b>{" "}
            in healthcare, substance abuse, mental health, job training and
            employment, and life skills development.
          </Paragraph>
          <Paragraph>
            We stick with people on their journey towards sustainable housing,
            and our holistic care focuses on developing the abilities and
            confidence to enter a new, empowered stage of their lives.{" "}
            <b>
              EOCP staff know that every person who comes through Crossroads’
              doors has what it takes to thrive.
            </b>
          </Paragraph>
        </Container>

        <Box
          sx={{ mt: { xs: 5, sm: 0 }, ml: { sm: 3 }, minWidth: { sm: 320 } }}
        >
          <ImageBlock img={crossroadsOutsideImg} imgOffset={"28vw"}>
            <Typography variant="body2" display="block">
              Located in the heart of East Oakland, <b>Crossroads</b> (built
              2008) <b>is the largest shelter in Alameda County.</b> It was
              developed through environmentally responsible strategies, from
              green construction materials to interior design that emphasized
              healthy living practices. To this day, Crossroads operates solar
              electric panels to defray 24/7 energy costs, relies on non-toxic
              supplies and materials, and implements other green approaches to
              support those housed there, and EOCP’s overall mission. It’s
              shifted the field standard for what clean, warm, and supportive
              facilities for those experiencing homelessness can look like.
            </Typography>
          </ImageBlock>
        </Box>
      </Box>

      <Grid container spacing={{ xs: 0, sm: 4 }}>
        <Grid item xs={12} sm={6} mb={5}>
          <Box
            component="img"
            display="block"
            src={kidsImg}
            sx={{ width: "100%" }}
            alt="Kids at play"
          />
          <Blockquote
            id="family-housing"
            overline="Families & Kids"
            invert
            mt={0}
          >
            EOCP provides parents/caregivers and their children with a place to
            stay and inclusive family services, so they can regain their footing
            towards a sustainable, resilient life.
          </Blockquote>

          <Container>
            <Paragraph>
              For single parent/caregiver families seeking a 24-hour support
              community, EOCP operates <b>Matilda Cleveland</b>, a 14-unit
              living complex. <b>Families in Transition</b> offers short-term
              places to stay in private residential units scattered across
              Oakland, and <b>Family Matters</b> provides the shelter,
              structure, and resources for families to rapidly find permanent
              housing in the community.
            </Paragraph>
            <Paragraph>
              Throughout all our family programs, EOCP Case Managers provide
              personalized, hands-on social services to children and caregivers
              alike through{" "}
              <b>
                enriched play and academic activities, school placement and
                childcare, employment and continued education
              </b>{" "}
              assistance, <b>life skills training</b> to emphasize positive
              behaviors for community living, and mental health{" "}
              <b>counseling</b> to strengthen and heal relationships between
              caregivers and children.
            </Paragraph>
          </Container>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            component="img"
            display="block"
            src={youngAdultsImg}
            sx={{ width: "100%" }}
            alt="Young adults at Our House"
          />
          <Blockquote id="our-house" overline="Young Adults" invert mt={0}>
            <b>Our House</b> is a safe landing pad for young adults experiencing
            homelessness while working towards a journey of self-empowerment and
            thriving in their adult lives.
          </Blockquote>
          <Container>
            <Paragraph>
              This 10-bed program for people ages 18 to 25 provides a safe,
              consistent, and nurturing environment within which to grow,
              including:
            </Paragraph>
            <Paragraph component="ul">
              <li>
                Life skills training, including approaches to financial
                management
              </li>
              <li>Mental health counseling</li>
              <li>Support for job training or education pathways</li>
              <li>
                Friendship and trusted guidance at a critical time in their
                lives
              </li>
            </Paragraph>
          </Container>
        </Grid>
      </Grid>
      <Box sx={{ backgroundColor: "green.50" }} py={4} mt={{ xs: 0, sm: 5 }}>
        <Container>
          <SubHeader>Learn more about our approach and our programs</SubHeader>
          <ImageNavList
            links={[
              "/what-we-do",
              "/why-we-do-this-work",
              "/community-based-services",
            ]}
          />
        </Container>
      </Box>
    </Layout>
  );
}
