import * as React from "react";
import ImageNav from "./ImageNav";
import BorderedList from "./BorderedList";

export default function ImageNavList({ links }) {
  return (
    <BorderedList
      items={links.map((to) => (
        <ImageNav to={to} key={to} />
      ))}
    />
  );
}
