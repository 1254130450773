import * as React from "react";
import Grid from "@mui/material/Grid";

export default function BorderedList({ items }) {
  return (
    <Grid container spacing={{ xs: 0, sm: 4 }}>
      {items.map((item, idx) => (
        <Grid
          item
          xs={12}
          lg={Math.max(3, Math.ceil(12 / items.length))}
          sm={Math.max(4, Math.ceil(12 / items.length))}
          key={idx}
          sx={{
            borderColor: "divider",
            borderStyle: "solid",
            borderWidth: {
              xs: "1px 0 0 0",
              sm: 0,
            },
            "&:last-child": {
              borderBottomWidth: {
                xs: 1,
                sm: 0,
              },
            },
          }}
        >
          {item}
        </Grid>
      ))}
    </Grid>
  );
}
