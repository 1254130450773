import Box from "@mui/material/Box";
import Container from "src/components/Container";
import Typography from "@mui/material/Typography";
import * as React from "react";

export default function ImageBlock({ title, img, children }) {
  return (
    <>
      {title && (
        <Container>
          <Typography
            variant="overline"
            color="secondary"
            component="h3"
            mb={2}
          >
            {title}
          </Typography>
        </Container>
      )}
      <Box>
        <Container
          sx={{
            background: (theme) =>
              `linear-gradient(180deg, transparent 50%, ${theme.palette.green[50]} 50%)`,
          }}
        >
          <Box
            component="img"
            src={img}
            sx={{ width: "100%", display: "block" }}
          />
        </Container>
        <Container
          sx={{
            backgroundColor: "green.50",
          }}
        >
          <Box py={3}>{children}</Box>
        </Container>
      </Box>
    </>
  );
}
